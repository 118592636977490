import {RouterDirection} from '@ionic/core';
import {AuthService} from '../services/auth.service';
import {FixedEncodeURIComponent} from './string-utils';
import {AccountService} from "../services/account.service";
import {StorageService} from "../services/storage.service";
import {i18n} from "../services/i18n.service";
import {ErrorToast} from "./default-toast";
import {ShoppingCartService} from "../services/shopping-cart.service";
import {EnvironmentService} from "../services/environment.service";
import {IError} from "../interfaces/error";




export const GetDomain = () => {
  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
}


export const RouterNavigate = async (path:string, direction: RouterDirection = 'forward') => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.push(path, direction)
  }else{
    location.href=path;
  }
};


export const IsLoggedInGuardWithUserCheck = () => {
  const isLoggedIn = AccountService.isLoggedIn(AccountService.getAccount()?._id)
  if (isLoggedIn && AuthService.isAdmin()) {
    return true;
  } else {
    StorageService.set('budget', true);
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const IsLoggedInGuard = () => {
  const isLoggedIn = AccountService.getAccount()?._id
  if (isLoggedIn) {
    return true;
  } else {
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const CanSeeArticles = () => {
  const isLoggedIn = AccountService.getAccount()?._id
  const environment = EnvironmentService.getEnvironment();
  if (isLoggedIn && environment.privateShop) {
    return true;
  } else if(environment.privateShop === false) {
   return true
  }else{
    return {redirect: '/not-logged-in'};
  }
}

export const HasShoppingCart = () => {
  let hasShoppingCart:number
  ShoppingCartService.shoppingCartCount.subscribe(count => hasShoppingCart = count);
  if (hasShoppingCart) {
    return true;
  } else {
    window.history.back()
  }
}

export const  OpenUrl = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const href = event.currentTarget.querySelector('a').getAttribute("href");
  RouterNavigate(href,'forward').then();
}

export const RouterBack = () => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.back();
  }else{
    history.back();
  }
};

export const RouterCanNavGoBackThenGo = async () => {
  const nav: HTMLIonNavElement = document.querySelector('ion-nav');
  if (nav && await nav.canGoBack()) {
    return nav.pop({skipIfBusy: true});
  }
  return RouterNavigate("/", 'back').then();
};

export const RouterOpenPayment = (event) => {
  event.preventDefault();
  const successUrl = location.href.split('?')[0];
  RouterNavigate('/payment/'+btoa(FixedEncodeURIComponent(successUrl))).then();
};

export const RouterGetUriParam = (name) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has(name)) {
    return urlParams.get(name);
  }else{
    return null;
  }
};

export const GetCategoryId = (path:string) => {
  if (path.includes('article-list')){
    if (isNaN(parseInt(path.split('/article-list/')[1]))){
      return  0
    }
    return parseInt(path.split('/article-list/')[1])

  }
};



export const RouterErrorHandling = async (error:IError) => {
  switch (error?.systemCode) {
    case "addressnotfound":
      await ErrorToast(i18n('Unfortunately we could not find an assigned address.').t('de', 'Leider konnten wir keine zugeordnete Adresse finden.').get())
      break;
    case "addressnotcreated":
      await ErrorToast(i18n('Unfortunately the address could not be created.').t('de', 'Die Adresse konnte leider nicht erstellt werden.').get())
      break;
    case "inputdatanotvalid":
    case "useralreadyexists":
      break
    case "wrongpassword":
      await ErrorToast(i18n('Unfortunately your password is incorrect.').t('de', 'Leider ist dein Passwort falsch.').get())
      break
    case "shopnotfound":
      await ErrorToast(i18n('Unfortunately we could not find a shop with this Id.').t('de', 'Leider konnten wir keinen Shop mit dieser Id finden.').get())
      break
    case "usernotinshop":
    case "shopidmismatch":
      AuthService.logout();
      location.href = '/';
      await ErrorToast(i18n('Unfortunately we could not find a user with this email.').t('de', 'Wir konnten leider keinen User mit dieser Email finden.').get())
      break;
    case "authenticationheaderisinvalid":
    case "authenticationhasfailed":
    case "authenticationrequired":
      await ErrorToast(error.message)
      AuthService.logout();
      location.href = '/';
      break;
    default:
      await ErrorToast(error.message)
  }


};
